<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import { GetQueryString } from "@/assets/js/filterTree";

export default {
  created() {
    let mainId = GetQueryString('mainId');
    let corpId = GetQueryString('corpId');
    if (mainId){
      sessionStorage.setItem('_mainId',mainId)
    }
    if (corpId){
      sessionStorage.setItem('_corpId',corpId)
    }
    let systemLanguage = sessionStorage.getItem('systemLanguage');
    if (systemLanguage){
      this.$i18n.locale = systemLanguage
      // this.$i18n.locale = "en";
    }
  }
}
</script>
<style lang="less">
	@import "./assets/less/common.less";
.dm-plugin-select-box-popover {
    padding: 20px 0 !important;
}
.modelDrawerContainerPopover {
    margin-left: 60px !important;
    padding: 0 !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  font-size:14px;
  text-align: left;
  height: 100%;
}
html{
  height: 100%;
}
body{
  margin: 0!important;
  height: 100%;
}
::-webkit-scrollbar {
  width: 6px;
  height: 12px;
  /* background-color:#E7ECF6;*/
  background-color: rgba(0,0,0,0);
}
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color:#BFCEEC;
}
.commitLoadMore{
  min-width: 110px!important;
  padding: 8px 0!important;
  .popoverItem{
    display: flex;
    align-items: center;
    height: 36px;
    cursor:pointer;
    padding: 0 12px;
    i{
      margin-right: 10px;
      font-size: 14px;
    }
  }
  .popoverItem:hover{
    background: #F5F8FF;
  }
}

</style>
